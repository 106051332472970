export const staticData = {
    width: 300,
    height: 250,
    promotions: [],
    layout: '',
    cm: '',
    layoutType: '',
    link: '',
    eventSource: '',
    trackEventSource: '',
    uniqBrands: [],
    isClickExclude: false,
    brandId: null,
    campaignId: null,
    viewSettings: null,
    pixels: [],
    adId: null,
    maxPriceDifferencePercent: 0,

    twPixelUrl: null
};

export const getData = (field) => {
    return staticData[field];
};

export const setData = (field, value) => {
    staticData[field] = value;
};

export const getTypeOfUnit = () => {
    if (staticData.link === 'pdp') return 'to-lander';
    if (staticData.link === 'shop' || staticData.brandId || staticData.campaignId) return 'to-brand';
    return 'to-marketplace';
};

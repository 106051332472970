import { useState } from 'react';
import { throttle } from 'lodash';
import * as find from 'lodash/find';
import * as flatten from 'lodash/flatten';
import * as get from 'lodash/get';
import * as map from 'lodash/map';
import * as uniqBy from 'lodash/uniqBy';
import { v4 as uuidv4 } from 'uuid';
import { giftly, widgetPromotionsAPI } from '../config/api';
import { klicklyBrandingClassNamesMap } from '../constants/klicklyBrandingClassNamesMap';
import {
    analyticsEventMap,
    liveRampEventMap,
    PROMOTIONS_DEFAULT_PARAMS,
    widgetSizeCountProductsInUnit
} from '../shared/constants';
import { xhrGet } from '../shared/services/http';
import { getNeededUnit, getWidgetSizeCountProductsInUnit } from '../shared/utils';
import { TrackCustomEvent } from '../shared/utils/events';
import getParam from '../shared/utils/getParam';
import { create as createIframe } from '../shared/utils/iframe';
import { load as loadLiverampPixel } from '../shared/utils/liveramp';
import { addKlicklyUTMParams, appendBasisExcludeScript, loadURL, timeElapsed } from '../shared/utils/static';
import { BrowserStorage } from '../storage/Browser';
import { getData, getTypeOfUnit, setData } from './stores/data';
import { TouchData } from './stores/touch';

const getClickedElementRef = (className) => {
    if (className.indexOf('__track-logo') !== -1) return 'logo';
    if (className.indexOf('__track-offerMessage') !== -1) return 'offer-message';
    if (className.indexOf('view-carousel') !== -1) return 'blank-space';
    if (className.indexOf('slick-prev') !== -1) return 'arrows';
    if (className.indexOf('slick-next') !== -1) return 'arrows';

    return null;
};

const numberToMongodbId = (id) => {
    if (!id) return '';
    const sId = id.toString();
    return `${'0'.repeat(24 - id.length)}${sId}`;
};

export function useData() {
    const [isSpinner, setIsSpinner] = useState(true);
    const touchData = new TouchData();
    let interactElements = {};

    const getWidgetSize = () => {
        const size = `${getData('width')}x${getData('height')}`;
        if (Object.keys(widgetSizeCountProductsInUnit).includes(size)) return size;
        return null;
    };

    const handleUserEvent = (uniqId, isEnd = false, trackData = []) => {
        // start mouseover/touchmove
        if (!isEnd) {
            if (interactElements[uniqId]) {
                interactElements[uniqId].resume();
            } else {
                interactElements[uniqId] = new TrackCustomEvent(trackData);
            }
        } else if (interactElements[uniqId]) {
            interactElements[uniqId].pause();
        }
    };

    const handleMouseMove = (uniqId, className) => {
        if (klicklyBrandingClassNamesMap.includes(className) && uniqId in interactElements) {
            interactElements[uniqId].addBrandedLogoElem('klicklyBrandingLogo');
        }
    };

    const handleMouseEnter = (promotion) => {
        if (window.parent) {
            window.parent.postMessage({ promotionMouseOver: true, promotion: promotion }, '*');
        }
    };

    const sendAllMouseOver = () => {
        const pData = flatten(map(interactElements, (elem) => elem.end()));
        interactElements = {};

        sendEvent({
            eventName: 'mouseover',
            promotions: pData,
            additionalData: { mouseoverLength: get(pData, '0.eventLength', 0) },
            event: get(pData, '0.eventElement', '')
        });
    };

    const throttledTouchMove = throttle((event) => {
        const touchedProductId = touchData.getTouchedProductId(event);

        if (!touchedProductId) {
            handleUserEvent('widget-window', false, getData('uniqBrands'));
            return;
        }

        const touchedPromotion = getData('promotions').find((promotion) => promotion._id === touchedProductId);
        handleUserEvent(touchedProductId, false, [touchedPromotion]);
    }, 25);

    const handleTouchStart = (event) => {
        touchData.setFirstTouchEvent(event);
    };

    const sendTouchStart = () => {
        const event = touchData.firstTouchEvent;
        const touchedProductId = touchData.getTouchedProductId(touchData.firstTouchEvent);
        const promotion = find(getData('promotions'), { _id: touchedProductId });

        const touch = event.touches[0];
        touchData.setStartData(event.timeStamp, touch);

        const data = touchData.buildTouchData(touch, event);

        if (!promotion) {
            return sendEvent({
                eventName: 'touchstart',
                promotions: getData('uniqBrands') || [],
                typeOfUnit: getClickedElementRef(event.target.className),
                additionalData: { isShopLink: getData('link') === 'shop' },
                event,
                touch: data
            });
        }

        sendEvent({
            eventName: 'touchstart',
            promotions: [promotion],
            event,
            touch: data
        });
    };

    const handleTouchEnd = (event) => {
        const touchedProductId = touchData.getTouchedProductId(event);

        Object.keys(interactElements).forEach((uniqId) => {
            handleUserEvent(uniqId, true);
        });

        sendTouchEnd(event, touchedProductId);
    };

    const sendTouchMove = () => {
        const pData = flatten(map(interactElements, (elem) => elem.end()));
        interactElements = {};

        if (!pData) {
            return;
        }

        sendEvent({
            eventName: 'touchmove',
            promotions: pData,
            additionalData: { touchMoveLength: get(pData, '0.eventLength', 0) },
            event: get(pData, '0.eventElement', ''),
            touch: {
                timestamp: touchData.timestampVector,
                moveVector: touchData.moveVector
            }
        });
    };

    const sendTouchEnd = (event, id) => {
        const promotion = find(getData('promotions'), { _id: id });

        const touch = event.changedTouches[0];
        touchData.setEndData(event.timeStamp, touch);

        const data = touchData.buildTouchData(touch, event);

        sendTouchStart();
        sendTouchMove();

        if (!promotion) {
            return sendEvent({
                eventName: 'touchend',
                promotions: getData('uniqBrands') || [],
                typeOfUnit: getClickedElementRef(event.target.className),
                event,
                touch: data
            });
        }

        sendEvent({
            eventName: 'touchend',
            promotions: [promotion],
            event,
            touch: data
        });

        touchData.reset();
    };

    const onClickAnyWhereHandler = (linkToOpenOnClick, event = null) => {
        if (!linkToOpenOnClick) return false;

        if (event && klicklyBrandingClassNamesMap.includes(event.target.className)) {
            window.open('https://klickly.com/');
            sendAllMouseOver();
            return false;
        }

        if (getData('eventSource') !== 'chrome-extension') {
            window.open(linkToOpenOnClick);
            sendAllMouseOver();
        }
    };

    const loadClickExclude = () => {
        if (!getData('isClickExclude')) {
            loadURL('https://cdn.klickly.com/pixels/click-exclude.js');
            setData('isClickExclude', true);
        }
    };

    const handleClickAnyWhere = (event) => {
        loadClickExclude();
        dspClickTracking();

        sendEvent({
            eventName: 'clicked',
            promotions: getData('uniqBrands') || [],
            typeOfUnit: getClickedElementRef(event.target.className),
            additionalData: { isShopLink: getData('link') === 'shop' },
            event
        });
    };

    const dspClickTracking = () => {
        const macroUrl = getData('cm');

        if (macroUrl) {
            fetch(macroUrl, {
                mode: 'no-cors',
                method: 'GET'
            });
        }
    };

    const handleClickProduct = ({ event, id, openShopHome = false }) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        dspClickTracking();

        const twPixelUrl = getData('twPixelUrl');

        if (twPixelUrl && twPixelUrl.searchParams && twPixelUrl.searchParams.get('track_view_event_on') === 'click') {
            createTwIframe();
        }

        const promotion = find(getData('promotions'), { _id: id });
        loadClickExclude();

        if (!promotion) return;

        const windowOpen = window.open();
        const typeOfUnit = getTypeOfUnit();

        sendAllMouseOver();
        // wait until events saved
        sendEvent({
            eventName: 'clicked',
            promotions: [promotion],
            typeOfUnit,
            event
        });

        let linkToOpen = addKlicklyUTMParams(
            `${giftly.baseURL}${giftly.endPoints.product(
                getParam('version', 'v1') === 'v1' ? promotion._id : numberToMongodbId(promotion._id)
            )}`
        );

        if (typeOfUnit === 'to-brand') {
            const klpixelValue = BrowserStorage.get('_klpixel_cid') || null;
            const klpixelQueryParam = klpixelValue ? `&_klpixel_cid=${klpixelValue}` : '';
            const { handle = null, sku = null, connectedDomain, storeType = 'shopify' } = promotion;

            let utmParams = promotion.utmParams;

            if (klpixelQueryParam) utmParams += klpixelQueryParam;

            const productPaths = {
                shopify: handle && handle.length ? `/products/${handle}` : '',
                magento: sku && sku.length ? `/${sku}.html` : ''
            };

            if (promotion.productUrl) {
                linkToOpen = promotion.productUrl + '?' + utmParams;
            } else {
                linkToOpen = !openShopHome
                    ? `https://${connectedDomain}${productPaths[storeType] || ''}?${utmParams}`
                    : `https://${connectedDomain}?${utmParams}`;
            }
        }

        windowOpen.location.replace(linkToOpen);
    };

    const getPromotions = async () => {
        const initObject = { ...PROMOTIONS_DEFAULT_PARAMS };
        const pathname = widgetPromotionsAPI.endPoints.promotions.getPromotions[getData('version')];
        const baseUrl = widgetPromotionsAPI.baseURL[getData('version')];

        const url = new URL(`${baseUrl}${pathname}`);
        const unit = getNeededUnit(initObject.width, initObject.height);
        if (!unit) {
            return false;
        }

        const countProductsInUnit = getWidgetSizeCountProductsInUnit(initObject.width, initObject.height);

        const params = {
            page: 1,
            perPage: countProductsInUnit,
            adsSource: getParam('adsSource', 'widget'),
            width: initObject.width,
            height: initObject.height
        };

        if (initObject.promotionId) {
            params.promotionId = initObject.promotionId;
        }

        if (initObject.brandId) {
            params.brandId = initObject.brandId;
        }

        if (initObject.campaignId) {
            params.campaignId = initObject.campaignId;
        }

        if (initObject.updateCache) {
            params.updateCache = initObject.updateCache;
        }

        const fetchedData = await xhrGet(url.toString(), params);

        const {
            promotions = [],
            viewSettings = {},
            exclude = false,
            excludeStudy = false,
            pixels = [],
            aggregatedData = {},
            creativeDetails = null
        } = fetchedData || {};

        if (initObject.promotionId && !initObject.brandId && promotions[0]) {
            initObject.brandId = promotions[0].account;
        }

        if (promotions.length && promotions.length < countProductsInUnit) {
            let i = 0;
            do {
                promotions.push(promotions[i]);
                i++;
            } while (promotions.length < countProductsInUnit);
        }

        initObject.promotions = promotions;
        initObject.viewSettings = viewSettings;
        initObject.exclude = exclude;
        initObject.excludeStudy = excludeStudy;
        initObject.pixels = pixels;
        initObject.maxPriceDifferencePercent = aggregatedData?.maxPriceDifferencePercent || 0;
        initObject.creativeDetails = creativeDetails;

        return initObject;
    };

    const init = async ({ adId, eventSource, layout, cm, layoutType, version = 'v1' }) => {
        setData('adId', adId);
        setData('eventSource', eventSource);
        setData('layout', layout);
        setData('layoutType', layoutType);
        setData('cm', cm);
        setData('version', version);

        const result = await getPromotions();

        if (!result) return;

        if (window.klpixel) {
            window.klpixel('meta', 'size', {
                width: result.width,
                height: result.height
            });
        }

        if (!layout && result.creativeDetails) {
            setData('layout', result.creativeDetails.layout);
            if (window.klpixel) {
                window.klpixel('meta', 'creativeDetails', result.creativeDetails);
            }
        }

        const { brandId: account = null } = result;
        sendEvent({
            eventName: 'impression',
            promotions: [{ account, _id: null, campaign: null }]
        });

        const eventData = { adId, impression: true, accountId: account };

        if (window.klpixel) {
            window.klpixel('event', 'pageView', { widget: eventData });
        }

        if (!result.promotions && !result.promotions.length) return;

        result.adId = adId;

        setData('width', result.width);
        setData('height', result.height);

        setData('brandId', result.brandId);
        setData('link', result.link);
        setData(
            'uniqBrands',
            uniqBy(result.promotions, 'account').map(({ account, shopDomain }) => {
                return {
                    _id: null,
                    campaign: null,
                    account,
                    shopDomain
                };
            })
        );

        setData('promotions', result.promotions);
        setData('viewSettings', result.viewSettings);
        setData('pixels', result.pixels);
        setData('maxPriceDifferencePercent', result.maxPriceDifferencePercent);

        if (result.pixels.length) {
            result.pixels.forEach((pixel) => {
                if (pixel.name === 'tw') {
                    enableTWTracking(pixel);
                }
            });
        }

        sendEvent({
            eventName: 'loaded',
            promotions: result.promotions
        });

        if (result.exclude) loadURL('https://cdn.klickly.com/pixels/exclude.js');

        if (result.excludeStudy) appendBasisExcludeScript();

        setIsSpinner(!result.promotions.length);
    };

    const createTwIframe = () => {
        return createIframe({ id: 'kl_tw_iframe', src: getData('twPixelUrl') });
    };

    const enableTWTracking = (pixel) => {
        const twPixelUrl = new URL(pixel.src);
        setData('twPixelUrl', twPixelUrl);

        if (twPixelUrl.searchParams.get('track_view_event_on') === 'view') {
            createTwIframe();
        }
    };

    const sendEvent = ({
        eventName,
        promotions,
        typeOfUnit = null,
        additionalData = {},
        event = null,
        touch = null
    }) => {
        const widgetSize = getWidgetSize();

        if (!getData('promotions').length || !widgetSize || !promotions.length) return false;

        const servedInfo = {
            eventId: uuidv4(),
            isLogIn: false,
            timeElapsed: timeElapsed(),
            adId: getData('adId'),
            widgetSize
        };

        // loop only once & prepare track, pixel, uniq accounts data
        const pData = promotions.reduce(
            (acc, promotion) => {
                if (analyticsEventMap[eventName]) {
                    const eventDataset = event?.target?.dataset;
                    const eventDatasetLength = eventDataset ? Object.keys(event.target.dataset).length : 0;
                    const price = get(promotion, 'variants.[0].price');

                    acc.analyticEvents.push({
                        widget: {
                            adId: getData('adId'),
                            size: servedInfo.widgetSize,
                            element: event
                                ? {
                                      id: get(event, 'target.id') || undefined,
                                      className: get(event, 'target.class') || undefined,
                                      tagName: get(event, 'target.tagName', '').toLowerCase() || undefined,
                                      text: get(event, 'target.childNodes.0.nodeValue') || undefined,
                                      src: get(event, 'target.src') || undefined,
                                      href: get(event, 'target.href') || undefined,
                                      dataset: eventDatasetLength ? event.target.dataset : undefined
                                  }
                                : undefined,
                            promotion: {
                                _id: promotion._id || undefined,
                                externalId: promotion.externalId || undefined,
                                title: promotion.title || undefined,
                                accountId: promotion.account || undefined,
                                campaignId: promotion.campaign || undefined,
                                campaignName: promotion.campaignName || undefined,
                                brandName: promotion.brandName || undefined,
                                qty: 1,
                                price: Number(price),
                                shopDomain: promotion.shopDomain,
                                version: getData('version') || 'v1'
                            },
                            ...(additionalData || {})
                        }
                    });
                }

                if (liveRampEventMap[eventName]) {
                    acc.liverampEvents.push({
                        unit: getData('adId'),
                        action: liveRampEventMap[eventName],
                        promotion: promotion._id || undefined,
                        account: promotion.account || undefined,
                        campaign: promotion.campaign || undefined,
                        shopDomain: promotion.shopDomain || undefined,
                        typeOfUnit
                    });
                }

                return acc;
            },
            { analyticEvents: [], liverampEvents: [] }
        );

        if (analyticsEventMap[eventName] && window.klpixel) {
            window.klpixel(
                'event',
                analyticsEventMap[eventName],
                pData.analyticEvents,
                {
                    touchTime: touchData.timestampVector
                },
                { touch }
            );
        }

        if (liveRampEventMap[eventName]) {
            loadLiverampPixel({
                platform: 'klickly',
                source: getData('eventSource'),
                events: pData.liverampEvents
            });
        }

        return true;
    };

    return {
        isSpinner,
        init,
        handleTouchStart,
        throttledTouchMove,
        handleTouchEnd,
        handleUserEvent,
        handleMouseEnter,
        handleMouseMove,
        sendAllMouseOver,
        onClickAnyWhereHandler,
        handleClickAnyWhere,
        handleClickProduct
    };
}
